import Alpine from 'alpinejs';

const apiUrl = process.env.CONCERTS_API_URL;
const fetchConcerts = async () => {
  try {
    const concertData = (await fetch(apiUrl)).json();
    return concertData;
  } catch (error) {
    return [
      {
        title: 'Fehler 500: Crisis on Infinite Earths',
        dateStart: '3048-11-13',
        link: 'https://de.wikipedia.org/wiki/Crisis_on_Infinite_Earths',
        bands: [
          {
            name: 'Die aktuellen Daten konnten leider nicht abgerufen werden, bitte versuche es später noch einmal.',
          },
        ],
      },
    ];
  }
};

window.Alpine = Alpine;
Alpine.data('concerts', () => ({
  concerts: [],
  dateBeforeNow(date) {
    return Date.parse(date) < Date.now();
  },
  getDateDay(date) {
    const jsDate = new Date(date);
    return jsDate.getDate();
  },
  getDateMonth(date) {
    const jsDate = new Date(date);
    return jsDate.toLocaleDateString('de-DE', { month: 'long' });
  },
  getDateYear(date) {
    const jsDate = new Date(date);
    return jsDate.getFullYear();
  },
  async getData() {
    this.concerts = await fetchConcerts();
  },
}));
Alpine.start();

const bodyEl: HTMLElement = document.body;
bodyEl.classList.remove('no-js');
window.addEventListener('load', () => {
  setTimeout(() => {
    bodyEl.classList.add('initialized');
  }, 500);
});

const toggleConcerts = (trigger: HTMLElement) => {
  const hiddenConcerts = document.querySelectorAll('.concert--hidden');
  hiddenConcerts.forEach((element) => {
    element.classList.remove('concert--hidden');
  });
  trigger.remove();
};
window.toggleConcerts = toggleConcerts;
